<!-- 下载匣 -->
<template>
  <div class="word">
    <div v-for="(item, index) in downloadBoxList" :key="index">
      <table cellspacing="0">
        <tr v-if="index == 0">
          <th>{{ $t("itemNumber") }}</th>
          <th>{{ $t("downName") }}</th>
          <th>{{ $t("downStyle") }}</th>
          <th>{{ $t("updateTime") }}</th>
        </tr>

        <!-- <tr v-for="(item,index) in downloadBoxList" :key="index">
                    <td rowspan="2" class="numTitle">{{index+1}}</td>
                    <td class="contentTitle">ww</td>
                    <td class="numTitle">24</td>
                    <td class="numTitle">ww</td>
                </tr>-->
        <tr v-for="(item1, index1) in item.lists" :key="index1">
          <td
            v-if="index1 == 0"
            :rowspan="item.lists.length || 1"
            class="leftTitle"
          >
            {{ $t("numList1")[index] }}、{{ item.cate_name }}
          </td>
          <td class="contentTitle">
            <div>{{ item1.title }}</div>
          </td>
          <td class="numTitle fileStyle">
            <!-- <a class="fileStyle" :href="'http://chanxue.b.langqiyun.cn/'+item1.file_url"
                            style="text-decoration: none;color: #000000;">
                            {{item1.format}}
                        </a> -->
            <div v-if="item1.format == 'mp3声音档'">
              <a
                class="amp"
                href="http://www.anhsiangchan.org/mp3/music-mp3.htm"
                >{{ item1.format }}</a
              >
            </div>
            <div v-else @click="downLoad(item1)">
              {{ item1.format }}
            </div>
          </td>
          <td class="numTitle" style="box-sizing: border-box">
            <div v-if="item1.date">{{ item1.date }}</div>
            <div v-else>{{ item1.create_time }}</div>
          </td>
        </tr>
      </table>
    </div>

    <div class="tableBottomLine"></div>
  </div>
</template>

<script>
import { getTimeBeforeDay } from "../../common/js/common";
import { getCateList, getDownLoadList } from "../../api/apis";
export default {
  name: "",
  components: {},

  data() {
    return {
      // baseUrl: "http://chanxue.b.langqiyun.cn/api/index/fileDownload?",
      baseUrl: "http://www.anhsiangchan.org.tw/api/index/fileDownload",
      downloadBoxList: [
        {
          id: 49,
          cate_name: "耕耘导师诗歌及语法",
          lists: [
            {
              id: 3,
              title: "测试1",
              format: "txt格式",
              file_url: "",
              data: "2020-02-11 10:50:32",
            },
            {
              id: 3,
              title: "测试1",
              format: "txt格式",
              file_url: "",
              data: "2020-02-11 10:50:32",
            },
          ],
        },
        {
          id: 49,
          cate_name: "耕耘导师诗歌及语法",
          lists: [
            {
              id: 3,
              title: "测试1",
              format: "txt格式",
              file_url: "",
              data: "2020-02-11 10:50:32",
            },
            {
              id: 3,
              title: "测试1",
              format: "txt格式",
              file_url: "",
              data: "2020-02-11 10:50:32",
            },
          ],
        },
        {
          id: 51,
          cate_name: "耕耘导师法音",
          lists: [],
        },
        {
          id: 51,
          cate_name: "耕耘导师法音",
          lists: [],
        },
      ],
    };
  },

  methods: {
    //下载资源
    downLoad(item) {
      console.log(item);
      if (item.new_link) {
        window.open(item.new_link);
      } else if (item.file_url) {
        window.location.href =
          this.baseUrl + `?url=${item.file_url}&title=${item.title}`;
      }
      // console.log(this.baseUrl + `url=${item.url}&title=${item.title}`)
      // window.location.href =this.baseUrl + `?url=${item.url}&title=${item.title}`
    },
    //初始化数据
    initData(arr) {
      if (Array.isArray(arr)) {
        arr.forEach((item, index) => {
          if (item.lists && JSON.stringify(item.lists) === "[]") {
            arr[index].lists = [{}];
          } else if (!item.lists) {
            arr[index].lists = [{}];
          }
        });
      }
      return arr;
    },
  },
  created() {
    // this.downloadBoxList = this.initData(this.downloadBoxList);
    getCateList().then((res) => {
      getDownLoadList(res[4][2].id).then((res) => {
        if (Array.isArray(res)) {
          for (let item of res) {
            item.lists = item.lists;

            // item.lists = getTimeBeforeDay(
            //     item.lists,
            //     "data"
            // );
          }
        }
        this.downloadBoxList = this.initData(res || []);
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
.ta-l {
  text-align: left;
}

.pl5 {
  padding-left: 5px;
}

.word {
  width: 750px;
  margin: 20px 10px;

  .title {
    font-size: 14px;
    font-weight: 600;
  }
}

table, td, tr, th {
  border: 1px solid #ACACAC;
  border-bottom: 0px solid #ACACAC;
}

.tableBottomLine {
  width: 100%;
  height: 1px;
  background-color: #ACACAC;
}

table {
  width: 100%;
  font-size: 14px;

  th {
    line-height: 36px;
    font-weight: 600;
    font-size: 14px;
    // padding-left: 14px;
  }

  td {
    line-height: 30px;
  }

  .fileStyle:hover {
    color: #1482d9;
    cursor: pointer;
  }

  .amp {
    text-decoration: none;
    color: #000000;
  }

  .amp:hover {
    text-decoration: none;
    color: #1482d9;
    cursor: pointer;
  }

  .leftTitle {
    font-size: 14px;
    font-weight: 600;
    width: 180px;
    text-align: left;
    line-height: 20px;
    padding: 5px;
    box-sizing: border-box;
  }

  .numTitle {
    width: 100px;
  }

  .contentTitle {
    width: 378px;
    font-size: 15px;
    padding-left: 5px;
    text-align: left;
    box-sizing: border-box;
  }
}
</style>
